import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import UiExtension, {UiScope} from "@bloomreach/ui-extension";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import CmsDialog from "./CmsDialog";
import CmsField from "./CmsField";
import {UiDialog} from "./UiDialog";
import UiField from "./UiField";
import {langNames} from "@uiw/codemirror-extensions-langs";

async function render() {

    const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement
    );

    try {
        const ui: UiScope = await UiExtension.register();

        const routing = (
            <BrowserRouter>
                <Routes>
                    <Route path="/dialog" element={<CmsDialog ui={ui}/>}/>
                    <Route path="/" element={<CmsField ui={ui}/>}/>
                </Routes>
            </BrowserRouter>
        );

        root.render(routing)

    } catch (error: any) {
        console.log(error);
        console.error('Failed to register extension:', error.message);
        console.error('- error code:', error.code);


        const routing = (
            <BrowserRouter>
                <Routes>
                    <Route path="/dialog" element={<UiDialog onOk={value => console.log('onOk', value)}
                                                             data={"..."}
                                                             config={{theme: 'dark', extensions: ['tsx']} }
                    />}/>
                    <Route path="/"
                           element={<UiField data={"console.log('hello world!');"}
                                             config={{enableFullScreen: true, height: '200px', editable: true, extensions: ['tsx'], theme: 'dark'}}
                                             onChange={data => {
                                                 console.log('on change..', data)
                                                 console.log('all extension types', langNames)
                                             }}
                                             editMode={true}
                                             onOpenDialog={() => window.open("/dialog")}/>}/>
                </Routes>
            </BrowserRouter>
        );

        root.render(routing)

    }
}

render();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import {DialogProperties, DialogSize, UiScope} from "@bloomreach/ui-extension";
import UiField from "./UiField";

interface CmsFieldState {
    value: string
    editMode: boolean
    config: any
}

interface CmsFieldProperties {
    ui: UiScope
}

function encode(str: string) {
    return window.btoa(unescape(encodeURIComponent(str)));
}

function decode(str: string) {
    return decodeURIComponent(escape(window.atob(str)));
}

export default class CmsField extends React.Component<CmsFieldProperties, CmsFieldState> {

    constructor(props: CmsFieldProperties) {
        super(props);

        const config = JSON.parse(props.ui.extension.config);

        this.state = {
            config: config,
            editMode: false,
            value: "",
        }
    }

    componentDidMount() {
        this.getInitialValue(this.props.ui).then(value => this.setState({value: decode(value)}, () => this.props.ui.document.field.setHeight("auto")));
    }

    async getInitialValue(ui: UiScope) {
        try {
            const brDocument = await ui.document.get();
            this.setState({editMode: brDocument.mode === 'edit'});
            return await ui.document.field.getValue();
        } catch (error: any) {
            console.error('Failed to register extension:', error.message);
            console.error('- error code:', error.code);
        }
        return '';
    }

    async openDialog(ui: UiScope, value: string) {
        try {
            const dialogOptions: DialogProperties = {
                title: 'Code',
                url: './dialog',
                size: DialogSize.Medium,
                value: value
            };

            const response = await ui.dialog.open(dialogOptions) as unknown as string;
            this.setState({value: response});
            await ui.document.field.setValue(encode(response));
        } catch (error: any) {
            if (error.code === 'DialogCanceled') {
                return;
            }
            console.error('Error after open dialog: ', error.code, error.message);
        }

    }


    render() {
        const {value, config, editMode} = this.state;

        return (
            <UiField
                config={config}
                key={`${(value && value.length > 0) ? value.length : Math.floor(Math.random() * 100)}`}
                data={value}
                onChange={(data) => {
                    // this.setState({value: data}, () => {
                    this.props.ui.document.field.setValue(encode(data))
                    if (!config.height) {
                        this.props.ui.document.field.setHeight("auto");
                    }
                    // })
                }}
                editMode={editMode}
                onOpenDialog={(value) => this.openDialog(this.props.ui, value)}/>);
    }
}



import React from "react";
import {UiScope} from "@bloomreach/ui-extension";
import {UiDialog} from "./UiDialog";

interface CmsDialogState {
    value: string
    config: any
}

interface CmsDialogProperties {
    ui: UiScope
}

export default class CmsDialog extends React.Component<CmsDialogProperties, CmsDialogState> {

    constructor(props: CmsDialogProperties) {
        super(props);

        const config = JSON.parse(props.ui.extension.config);

        this.state = {
            value: "",
            config: config
        }

    }

    componentDidMount() {
        this.getInitialValue(this.props.ui).then(data => this.setState({value: data}));
    }

    async getInitialValue(ui: UiScope) {
        try {
            const options = await ui.dialog.options();
            return options.value;
        } catch (error: any) {
            console.error('Failed to register extension:', error.message);
            console.error('- error code:', error.code);
        }
        return [];
    }


    render() {
        const {value, config} = this.state;
        return (
            <UiDialog data={value} config={config} onOk={data => {
                this.props.ui.dialog.close(data)
            }}/>);
    }
}



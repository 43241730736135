import React from "react";
import CodeMirror from '@uiw/react-codemirror';
import {langs} from '@uiw/codemirror-extensions-langs';
import Grid from '@mui/material/Unstable_Grid2';
// import {Button} from "@mui/material";
// import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
// import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
// import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';


interface FieldState {
    data: string
    editMode: boolean
    config: any
}

interface FieldProperties {
    onChange: (data: string) => void
    onOpenDialog: (data: string) => void
    editMode: boolean
    data: string
    config: { height: string, extensions: [string], theme: 'dark' | 'light' } | any
}

export default class UiField extends React.Component<FieldProperties, FieldState> {

    constructor(props: FieldProperties) {
        super(props);

        this.state = {
            data: this.props.data,
            editMode: props.editMode,
            config: props.config
        }
    }


    render() {
        const {editMode, config, data} = this.state;
        //@ts-ignore
        const extensions = (config.extensions && config.extensions.length > 0) && config.extensions.map((extension: string) => langs[extension]())

        return (<Grid container spacing={2}>
                <Grid xs={12}>
                    <CodeMirror editable={editMode && config.editable}
                                value={data}
                                readOnly={config.readOnly}
                                height={config.height}
                                theme={config.theme}
                                onChange={value => this.setState({data: value}, () => this.props.onChange(value))}
                                extensions={extensions}/>
                </Grid>
            </Grid>
        );

    }


}
import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent} from "@mui/material";
import CodeMirror from "@uiw/react-codemirror";
import {langs} from "@uiw/codemirror-extensions-langs";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

interface DialogProperties {
    onOk: (data: string) => void
    data: string
    config: object
}

export function UiDialog({data, config, onOk}: DialogProperties): JSX.Element | null {

    const [value, setValue] = useState<string>(data)
    //@ts-ignore
    const extensions = (config.extensions && config.extensions.length > 0) && config.extensions.map((extension: string) => langs[extension]())

    return (<Dialog fullScreen open>
        <DialogContent >
            <CodeMirror editable={true}
                        value={value}
                        height={'100%'}
                //@ts-ignore
                        theme={config.theme}
                        onChange={value => setValue(value)}
                        extensions={extensions}/>
        </DialogContent>
        <DialogActions >
            <Button startIcon={<SaveOutlinedIcon/>} sx={{marginRight: 1}} variant={"contained"}
                    onClick={() => onOk(value)}>Save</Button>
        </DialogActions>
    </Dialog>)
}


